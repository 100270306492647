import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from './auth.interface';

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token!: string | null;

  constructor(private http: HttpClient) {}

  login(payload: { username: string; password: string }) {
    return this.http.post<Auth>('/sessions.json', payload);
  }

  logout() {
    this.clearToken();
  }

  isAuthenticated() {
    let isValid = !!this.getToken();
    return isValid;
  }

  setToken(resp: Auth) {
    this.token = resp.access_token;
    localStorage.setItem(TOKEN_KEY, this.token);
  }

  getToken() {
    if (this.token) {
      return this.token;
    }

    this.token = localStorage.getItem(TOKEN_KEY);
    return this.token;
  }

  clearToken() {
    localStorage.setItem(TOKEN_KEY, '');
  }

  activateUser(paylaod: { code: string; password: string; password_confirmation: string }) {
    return this.http.post<{ status: boolean }>('/user_activates/activate', paylaod);
  }

  forgotPasswordRequest(payload: { username: string }) {
    return this.http.post<{ status: boolean; data: { username: string; email: string } }>('/user_forgot_passwords', payload);
  }

  forgotPasswordSetPasswrod(paylaod: { code: string; password: string; password_confirmation: string }) {
    return this.http.post<{ status: boolean }>('/user_forgot_passwords/set_password', paylaod);
  }
}
